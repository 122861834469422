
import { Options, Vue } from 'vue-class-component';
import { apiReportGetListTotalNum,
         apiReportGetList } from '@/services/tpsApi/tpsApiReport';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '@/services/exportFile';
import { 
    IReportGetListRequestParams
 } from '@/services/tpsApi/models/IReport';
import ReportAbnormalDeductSearchMenu from '@/components/ReportAbnormalDeductSearchMenu.vue';
import Table from '@/components/Table.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    ReportAbnormalDeductSearchMenu,
    Table,
    Pagination
  },
})
export default class ReportAbnormalDeduct extends Vue {
    TABLE_HEAD: {} = {
        "卡號": "卡號",
        "營運規則版號": "營運規則版號",
        "發卡公司": "發卡公司",
        "卡別": "卡別",
        "品牌名稱": "品牌名稱",
        "車號": "車號",
        "駕駛員帳號": "駕駛員帳號",
        "卡機內碼": "卡機內碼",
        "交易日期": "交易日期",
        "交易時間": "交易時間",
        "交易類別": "交易類別",
        "扣款金額": "扣款金額",
        "使用點數": "使用點數",
        "使用錢包": "使用錢包",
        "使用現金": "使用現金",
        "異常交易類型": "異常交易類型"
    };

    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    reportList: [] | Blob = [];
    REPORT = "5"; //report=5，異常扣除明細表
    pageNo: {} = PAGENO;
    searchQuery: {} = {};

    mounted() {
        this.refreshTabel({report: this.REPORT, limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        let query = Object.assign({report: this.REPORT}, this.searchQuery);
        this.pageTotalNum = await apiReportGetListTotalNum(query);       
        console.log(this.pageTotalNum);
    }

    async refreshTabel(pData: IReportGetListRequestParams) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        if(pData && !pData.report) pData.report = this.REPORT;
        pData = Object.assign(pData, this.searchQuery);
        this.reportList = await apiReportGetList(pData);
    }

    async download(pData: { output: string}) {
        const res = await apiReportGetList(Object.assign({
                                            report: this.REPORT, 
                                            output: pData.output},
                                            this.searchQuery));
        exportFile({ fileName: "異常扣除明細表", data: res });
    }

    doSearch(pData) {
        this.searchQuery = Object.assign({}, pData);
        this.initPagination();
        this.refreshTabel({report: this.REPORT, limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
    }
}
